<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <div class="d-flex p-1 pb-2 header">
      <div class="d-flex actions mr-2">
        <div title="Create Contract" 
        class="btn btn-outline-secondary action-button"
        v-on:click="table.click(`create`, `contract`)">
          <i class="mr-1 fas fa-file-signature"></i>
          <span>Create Contract</span>
        </div>
      </div>
      <div class="flex-grow-1 text-right">
        <span v-text="table.records"></span>
      </div>
    </div>
    <template v-if="ready">
    <scroll-area class="pr-3">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <span>Number</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `number`)"
                v-on:click="table.sort(`number`)"
              ></i>
            </th>
            <th>
              <span>Name</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `name`)"
                v-on:click="table.sort(`name`)"
              ></i>
            </th>
            <th class="text-right">
              <span>Value</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `value`)"
                v-on:click="table.sort(`value`)"
              ></i>
            </th>
            <th>
              <span class="pl-3">Contract</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `contract`)"
                v-on:click="table.sort(`contract`)"
              ></i>
            </th>
            <th>
              <span>Updated</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `updated`)"
                v-on:click="table.sort(`updated`)"
              ></i>
            </th>
            <th>
              <checkbox
                :o="table"
                p="select"
                :title="table.title(`select`)"
                :disabled="table.disabled(`select`)"
                v-on:change="table.change(`select`)"
              ></checkbox>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.items" :key="index">
            <td class="pre">
              <a href="#"
                class="badge badge-custom"
                :title="table.title(`account`)"
                v-on:click="table.click(`account`, o)"
                v-text="o.number"
              ></a>
            </td>
            <td class="pre">
              <span v-text="o.name"></span>
            </td>
            <td class="text-right">
              <template v-if="o.value != table.NONE">
                <a href="#"
                  class="badge badge-custom"
                  :title="table.title(`records`)"
                  v-on:click="table.click(`value`, o)"
                  v-text="table.display(`value`, o)"
                ></a>
              </template>
              <template v-else>
                <span v-text="o.value"></span>
              </template>
            </td>
            <td class="text-center">
              <template v-if="o.contract != table.NONE">
                <a href="#"
                  class="badge badge-custom"
                  :title="table.title(`contract`)"
                  v-on:click="table.click(`contract`, o)"
                  v-text="o.contract"
                ></a>
              </template>
              <template v-else>
                <span class="pl-3" v-text="o.contract"></span>
              </template>
            </td>
            <td>
              <span v-text="o.updated"></span>
            </td>
            <td>
              <checkbox :o="o" p="select" :disabled="table.disabled(`select`, o)"></checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
    </template>
    <template v-else>
        <loading></loading>
      </template>
  </div>
</template>

<style scoped>
td.pre span {
  white-space: pre;
}
div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}
div.header div {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.8rem;
}
div.actions div:hover i,
div.filters i:hover {
  opacity: 1;
}
table span {
  font-size: 0.9rem;
}
span.link {
  cursor: pointer;
  text-decoration: underline;
}
table th:nth-child(1) {
  width: 7em;
}
/*
    table th:nth-last-child(5) {
        width: 7em;
    }
    */
table th:nth-last-child(4) {
  width: 11em;
}
table th:nth-last-child(3) {
  width: 9em;
}
table th:nth-last-child(2) {
  width: 10em;
}
table th:last-child {
  width: 2em;
}
td {
  vertical-align: inherit;
}
i {
  opacity: 0.3;
}
i:hover,
i.sort,
i.fa-spin,
i.remove {
  cursor: pointer;
  opacity: 1;
}
i.remove {
  color: red;
}
</style>

<script>
import { checkbox, scrollArea, search, loading } from "../../../../component";
import { Api, Component, List, Modal, Record } from "../../../../factory";
import { moment, numeral } from "../../../../npm";
import { alert, events, session } from "../../../../service";

export default {
  name: `ClientsHouseholdAccounts`,
  get components() {
    return {
      checkbox,
      scrollArea,
      search,
      loading
    };
  },
  props: [`list`],
  data() {
    return {
      table: ``,
      ready: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var state = session.get(`state`);
      // console.log(`state`, state);
      var { list } = this;
      var _this = this
      var table = {
        _data: ``,
        _items: ``,
        async _load() {
          if (this._loading) return;
          this._loading = true;
          try {
            var [{ data: accounts }, { data: contracts }] = await Promise.all(
              [`accounts/household-accounts?householdId=${state.household._id}`, `contracts?householdId=${state.household._id}&status=2`].map(
                (p) => Api.get(p)
              )
            );
            const accountContractMap = new Map()
            for (const contract of contracts) {
              // console.log('contract', contract);
              contract.accounts.forEach(acc => {
                accountContractMap.set(acc.number, contract)
              })
            }

            this._items = accounts
              .map((account) => {
                var value = account.value;
                // console.log('account', account);
                // console.log('value', value);
                if (typeof value != "number") {
                  value = this.NONE
                }

                let contract = accountContractMap.get(account.number)

                if (contract) {
                  account.contractId = contract._id
                  contract = Record.id(contract)
                } else {
                  contract = this.NONE
                }

                var updated = account.date
                  ? moment(account.date, `L`).format(`MMM D, YYYY`)
                  : this.NONE;

                var select = account.contractId ? false : true;

                return { ...account, contract, value, select, updated };
              })

              .sort((a, b) => a.number.localeCompare(b.number));
            this._data.accounts = accounts;
            this._data.contracts = contracts;
            this.select = this._items.find((o) => o.select) ? true : false;
          } catch (e) {
            console.error(e);
          } finally {
            this._loading = false;
            _this.ready = true 
          }
        },
        _loading: ``,
        _reload() {
          events.$emit(`nav`, `accounts`);
        },
        class(type, d) {
          switch (type) {
            case `sort`:
              return list.sort.key == d ? `sort` : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        change(type) {
          switch (type) {
            case `select`:
              this.items.forEach((o) => {
                if (!o.contractId) o.select = this.select;
              });
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        async click(type, d) {
          switch (type) {
            case `account`:
              return Modal.open(`account`, {
                account: d,
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  },
                },
              });
            case `contract`:
              try {
                var { data: contract } = await Api.get(
                  `contracts/${d.contractId}`
                );
                Modal.open(`contract`, {
                  contract,
                  methods: {
                    success: () => {
                      Modal.close();
                      this._reload();
                    },
                  },
                });
              } catch (e) {
                console.error(e);
                alert.error(e.message);
              }
              break;
            case `create`:
              switch (d) {
                case `contract`:
                  var { data: schedules } = await Api.get(`schedules`);

                  if (!schedules || !schedules.length) {
                    return alert.warning(
                      `There is no Schedule configured yet!`,
                      3e3
                    );
                  }

                  var items = this.items.filter((o) => o.select);

                  if (!items.length)
                    return alert.warning(
                      `Sorry, no accounts are selected or available!`,
                      3e3
                    );
                  var { contract } = this.state;
                  contract.accounts = this.items
                    .filter((o) => o.select)
                    .map((o) =>
                      this._data.accounts.find((a) => a._id == o._id)
                    );
                  return Modal.open(`contract-create`, {
                    contract,
                    methods: {
                      discard: () => {
                        this.state.contract = {};
                        Modal.close();
                      },
                      success: (d) => {
                        if (typeof d == `string`) alert.message(d, 2e3);
                        this.state.contract = {};
                        Modal.close();
                        events.$emit(`nav`, `contracts`);
                      },
                    },
                  });
                default:
                  throw new Error(`Invalid click create type, ${d}`);
              }
              break;
            case `value`:
              return d.NCA
                ? Modal.open(`NCA-account-values`, {
                  account: d,
                  methods: {
                    success: () => {
                      this._reload();
                    },
                  },
                })
                : Modal.open(`account-values`, { account: d });
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type, d) {
          switch (type) {
            case `select`:
              return d
                ? d.contractId
                  ? true
                  : false
                : this.items.find((o) => !o.contractId)
                  ? false
                  : true;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        display(type, d) {
          switch (type) {
            case `value`:
              return typeof d.value == "number" ? numeral(d.value).format(`$0,0.00`) : this.NONE;
            default:
              throw new Error(`Invalid display type, ${type}!`);
          }
        },
        init(c) {
          this._data = { accounts: [], contracts: [] };
          this.state = Component.state(c, {
            contract: {},
            list: {
              search: ``,
              sort: { asc: ``, key: `` },
            },
          });
          this.NONE = `-`;
          this._load();
          return this;
        },
        get items() {
          var { search, sort } = this.list;
          return List.sort(
            List.filter(this._items, search),
            sort.key,
            sort.asc
          );
        },
        get list() {
          return this.state.list;
        },
        NONE: ``,
        get records() {
          return List.records(this.items, this._items);
        },
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        state: ``,
        title(type, d) {
          switch (type) {
            case `account`:
            case `contract`:
              return `View ${type}`;
            case `records`:
              return `View account records`;
            case `select`:
              return `Select account(s)`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
      };
      this.table = table.init(this);
    },
  },
};
</script>
