<template>
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-file-alt mr-2"></i>
          <span class="font-weight-bold" v-text="invoice.text(`modal`)"></span>
        </p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <template v-if="invoice.ready">
          <scroll-area class="px-4 py-3">
            <div class="form-row">
              <div class="col-12">
                <p class="font-weight-bold">Invoice</p>
                <div class="form-row">
                  <div class="col-3">
                    <div class="form-group">
                      <date-picker :o="invoice" p="date" :options="invoice.options(`date`)"></date-picker>
                      <small class="form-text text-muted">Invoice Date</small>
                    </div>
                  </div>
                  <div class="col-3">
                    <select
                      v-model="invoice.type"
                      class="form-control form-control-sm"
                      :disabled="invoice.disabled(`type`)"
                      v-on:change="invoice.change(`type`)"
                    >
                      <option v-for="(o, i) in invoice.options(`type`)" :key="i">
                        <span v-text="o"></span>
                      </option>
                    </select>
                    <small class="form-text text-muted">Invoice Type</small>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="font-weight-bold">Period</p>
                <div class="form-row">
                  <div class="col-3">
                    <template v-if="invoice.advance">
                      <div class="form-group">
                        <date-picker
                          :o="invoice.period"
                          p="values"
                          :options="invoice.options(`period`, `values`)"
                        ></date-picker>
                        <small class="form-text text-muted">Period Values</small>
                      </div>
                    </template>
                    <template v-else>
                      <select
                        v-model="invoice.values"
                        class="form-control form-control-sm"
                        v-on:change="invoice.change(`values`)"
                      >
                        <option v-for="(o, i) in invoice.options(`values`)" :key="i">
                          <span v-text="o"></span>
                        </option>
                      </select>
                      <small class="form-text text-muted">Period Values</small>
                    </template>
                  </div>

                  <div class="col-3">
                    <div class="form-group">
                      <date-picker
                        :o="invoice.period"
                        p="start"
                        :options="invoice.options(`period`, `start`)"
                      ></date-picker>
                      <small class="form-text text-muted">Period Start</small>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <date-picker
                        :o="invoice.period"
                        p="end"
                        :options="invoice.options(`period`, `end`)"
                      ></date-picker>
                      <small class="form-text text-muted">Period End</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="d-flex">
              <div>
                <p class="font-weight-bold">Accounts Refund</p>
              </div>
              <div class="flex-grow-1 text-right">
                <span class="mr-2">Obfuscate account numbers</span>
                <checkbox :o="invoice.show" p="numbers" title="Toggle obfuscate account numbers"></checkbox>
              </div>
              <div class="text-right ml-4">
                <span class="mr-2">Show all accounts</span>
                <checkbox :o="invoice.show.all" p="due" title="Toggle all accounts"></checkbox>
              </div>
            </div>
            <table class="table table-sm accounts mb-5">
              <thead>
                <tr>
                  <th>
                    <span>Number</span>
                  </th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Household</span>
                  </th>
                  <th class="text-right">
                    <span>Billing</span>
                  </th>
                  <th class="text-right">
                    <span>Amount</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(o, i) in invoice.accounts(`due`)" :key="i">
                  <td>
                    <span v-text="o.number"></span>
                  </td>
                  <td>
                    <span v-text="o.name"></span>
                  </td>
                  <td>
                    <span v-text="o.household"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="o.billing"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`due`, o)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3"></td>
                  <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right">
                    <span class="total" v-text="invoice.text(`total`, `due`)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <hr />
            <div class="d-flex">
              <div>
                <p class="font-weight-bold">Account Fees</p>
              </div>
              <div class="flex-grow-1 text-right">
                <span class="mr-2">Show all fees</span>
                <checkbox :o="invoice.show.all" p="fees" title="Toggle all fees"></checkbox>
              </div>
            </div>
            <table class="table table-sm accounts mb-0">
              <thead>
                <tr>
                  <th>
                    <span>Number</span>
                  </th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Schedule</span>
                  </th>
                  <th class="text-right">
                    <span>Value</span>
                  </th>
                  <th class="text-right">
                    <span>Fee</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(o, i) in invoice.accounts(`fees`)" :key="i">
                  <td>
                    <span v-text="o.number"></span>
                  </td>
                  <td>
                    <span v-text="o.name"></span>
                  </td>
                  <td>
                    <span v-text="o.schedule"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`value`, o)"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`fee`, o)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"></td>
                  <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right">
                    <span class="total" v-text="invoice.text(`total`, `account-values`)"></span>
                  </td>
                  <td class="text-right">
                    <span class="total" v-text="invoice.text(`total`, `account-fees`)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <p class="text-right pr-3">
              <small class="text-muted" v-text="invoice.text(`owing`)"></small>
            </p>
            <hr />
            <div class="d-flex">
              <div>
                <p class="font-weight-bold">Schedules</p>
              </div>
              <div class="flex-grow-1 text-right">
                <span class="mr-2">Show all tiers</span>
                <checkbox :o="invoice.show.all" p="tiers" title="Toggle all tiers"></checkbox>
              </div>
            </div>
            <div v-for="(o, index) in invoice.schedules" :key="index">
              <hr class="mt-0" />
              <p class="schedule" v-text="invoice.text(`schedule`, o, index)"></p>
              <table class="table table-sm schedule mb-4">
                <thead>
                  <tr>
                    <th>
                      <span>Tier</span>
                    </th>
                    <th class="text-right">
                      <span>Rate</span>
                    </th>
                    <th></th>
                    <th class="text-right">
                      <span>Value</span>
                    </th>
                    <th class="text-right">
                      <span>Fee</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(split, i) of invoice.tiers(o)" :key="i">
                    <td>
                      <span v-text="invoice.text(`tier`, o, i)"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="invoice.text(`rate`, o, i)"></span>
                    </td>
                    <td></td>
                    <td class="text-right">
                      <span v-text="invoice.text(`split`, o, i)"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="invoice.text(`fee`, o, i)"></span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2"></td>
                    <td class="text-right">
                      <span class="font-weight-bold">Total</span>
                    </td>
                    <td class="text-right">
                      <span class="total" v-text="invoice.text(`total`, `schedule-splits`, o)"></span>
                    </td>
                    <td class="text-right">
                      <span class="total" v-text="invoice.text(`total`, `schedule-fees`, o)"></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <template v-if="o.owing">
                <p class="text-right">
                  <small class="text-muted" v-text="invoice.text(`owing`, o)"></small>
                </p>
              </template>
            </div>
          </scroll-area>
        </template>
        <template v-else>
          <loading></loading>
        </template>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            :disabled="invoice.disabled(`submit`)"
            v-on:click="invoice.click(`submit`)"
          >Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h3 {
  opacity: 0.5;
}
span {
  font-size: 0.9em;
}
span.multi {
  font-size: 0.8em;
  opacity: 0.7;
  font-style: italic;
}
small {
  padding-left: 0.7em;
  margin-top: 0.1em;
}
div.menu {
  height: 2.5em;
}
div.controls {
  width: 15em;
}
span.date {
  font-size: 0.7em;
}
button {
  width: 80px;
}
div.modal-body {
  height: calc(100vh - 12em);
}
div.accounts div.form-group {
  margin-bottom: 0.5em;
}
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
table.accounts th:nth-child(1) {
  width: 8%;
}
table.accounts th:nth-child(2) {
  width: 50%;
}
table.accounts th:nth-last-child(2) {
  width: 12%;
}
table.accounts th:nth-last-child(1) {
  width: 12%;
}
table.schedule th:nth-child(1) {
  width: 25%;
}
table.schedule th:nth-child(2) {
  width: 15%;
}
table.schedule th:nth-last-child(2) {
  width: 12%;
}
table.schedule th:nth-last-child(1) {
  width: 12%;
}
span.total {
  font-weight: 600;
}
span.title {
  font-size: 1em !important;
}
p.schedule {
  font-weight: 500;
}
</style>

<script>
import { checkbox, datePicker, loading, scrollArea } from "../../../../../component";
import { Api, lib, Record } from "../../../../../factory";
import { moment, numeral } from "../../../../../npm";
import { alert } from "../../../../../service";

export default {
  get components() {
    return {
      checkbox,
      datePicker,
      loading,
      scrollArea
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: o =>
        typeof o.contract == `object` &&
        typeof o.invoice == `object` &&
        typeof o.methods == `object` &&
        typeof o.methods.success == `function`
    }
  },
  data() {
    return {
      invoice: ``
    };
  },
  watch: {
    "invoice.period": {
      handler() {
        var { period } = this.invoice;
        if (period.start) this.invoice.fees();
      },
      deep: true
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { data } = this;
      var { contract, methods } = data;
      var invoice = {
        _account(o, h) {
          var a = this._data.accounts.find(a => a.number == o.number);
          var { name, number } = a;
          var { name: household } = h;
          var { name: schedule } = contract.schedules.find(s => s._id == o.scheduleId);
          var billing =
            o.billing == -1
              ? this._text.SELF
              : contract.accounts.find(ac => ac._id == o.billing).number; 
          var value = 0;
          var fee = 0;
          var owing = 0;
          var due = 0;
          return {
            billing,
            due,
            fee,
            household,
            name,
            number,
            owing,
            value,
            schedule
          };
        },
        _accounts: ``,
        _date(d) {
          var f = `MMM D, YYYY`;
          var { dates } = this._data;
          switch (d) {
            case `format`:
              return f;
            case `start`:
              return this.advance
                ? this._date(moment().format(`L`))
                : this._date(dates[0]);
            case `end`:
              return contract.status == 2
                ? this.advance
                  ? this._date(
                    moment()
                      .add(3, `months`)
                      .format(`L`)
                  )
                  : this._date(dates[dates.length - 1])
                : this._date(moment(contract._updated).format(`L`));
            default:
              return d.includes(`/`)
                ? moment(d, `L`).format(f)
                : moment(d, f).format(`L`);
          }
        },
        _data: { accounts: ``, dates: ``, fees: ``, households: `` },
        async _dates() {
          await this._update();
          var { period } = this;
          period.start = this._date(`start`);
          period.end = this._date(`end`);
        },
        _fees(d) {
          this._data.fees = d;
          this._accounts.forEach(a => {
            var account = d.accounts.find(o => o.number == a.number);
            a.value = account.value;
            a.due = account.due;
            a.fee = account.fee;
            a.owing = account.owing;
          });
          this.schedules.forEach((s, i) => {
            var schedule = d.schedules[i];
            s.minimum = schedule.minimum;
            s.splits = [...schedule.splits];
            s.fees = [...schedule.fees];
            s.owing = schedule.owing;
            s.total = schedule.total;
          });
        },
        _init: (...args) => this.$set(...args),
        async _load() {
          try {
            if (this._pending) return;
            this._pending = true;
            //                            var r = await Api.get(`records?contractId=${contract._id}&_dates=true`)
            //                          var { data: dates } = r
            //                        this._data.dates = dates
            var accounts = (
              await Promise.all(
                contract.accounts.map(o =>
                  Api.get(`accounts?number=${o.number}`)
                )
              )
            ).map(r => r.data[0]);
            var { data: household } = await Api.get(
              `households/${contract.householdId}`
            );
            this._data.accounts = accounts;
            this.ref = `${data.invoice.ref} (Refund)`;
            this._accounts = contract.accounts.map(o =>
              this._account(o, household)
            );
            this._schedules = contract.schedules.map(o => this._schedule(o));
            var { period } = this;
            period.start = this._date(data.invoice.period.start);
            period.end = this._date(this._date(this.date));
            period.values = this._date(data.invoice.period.values);
            this.ready = true;
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          } finally {
            this._pending = false;
          }
        },
        _pending: ``,
        _period: () => ({ start: ``, end: ``, values: `` }),
        _schedule(o) {
          var fees = [];
          var owing = 0;
          var total = { fees: 0, splits: 0 };
          var splits = [];
          return { ...o, fees, owing, splits, total };
        },
        _schedules: ``,
        _show() {
          var { all, numbers } = this.show;
          return { ...all, numbers };
        },
        async _submit() {
          if (this._pending) return;
          this._pending = true;
          try {
            var { _data, date, ref } = this;
            var { fees } = _data;
            var show = this._show();
            var { _id: contractId } = contract;
            var refund = true;
            var d = { contractId, date, fees, ref, refund, show };
            var r = await Api.post(`invoices`, d);
            methods.success(r.data);
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          } finally {
            this._pending = false;
          }
        },
        _text: { NONE: ``, SELF: `` },
        _type() {
          return this.options(`type`)[0];
        },
        _values() {
          return this.options(`values`)[0];
        },
        _update: () => new Promise(r => this.$nextTick(r)),
        accounts(type) {
          var account = o => {
            var len = Math.floor(o.number.length / 2);
            var number = this.show.numbers
              ? [...new Array(len).fill(`*`), o.number.slice(len)].join(``)
              : o.number;
            return { ...o, number, safeNumber: o.number };
          };
          switch (type) {
            case `due`:
              return this._accounts
                .filter(o => o.due || this.show.all.due)
                .map(o => account(o));
            case `fees`:
              var accounts = this._accounts.slice().map(o => account(o));
              var { exceptions = [] } = this._data.fees;
              exceptions.forEach(e => {
                var i = accounts.findIndex(a => a.safeNumber == e.account);
                if (i < 0) return;
                var number = this._text.NONE;
                var type = lib.string.capitalize(e.type);
                var ticker = e.ticker
                  ? contract.exceptions.find(
                    o => o.type == `position` && o.ticker.symbol == e.ticker
                  ).ticker
                  : ``;
                var exception = ticker
                  ? `Ticker: ${ticker.symbol} (${ticker.name})`
                  : type;
                var schedule = contract.schedules.find(s => s._id == e.scheduleId)?.name;
                var o = {
                  ...e,
                  name: exception,
                  number,
                  schedule
                };
                accounts.splice(i + 1, 0, o);
              });
              return accounts.filter(o => o.fee || this.show.all.fees);
          }
        },
        get advance() {
          return this.type == this.options(`type`)[0];
        },
        click(type) {
          switch (type) {
            case `submit`:
              return this._submit();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        change(type, d) {
          switch (type) {
            case `type`:
              return this._dates();
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        date: ``,
        disabled(type) {
          switch (type) {
            case `submit`:
              return this._pending;
            case `type`:
              return contract.hasOwnProperty(`billing`);
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        async fees() {
          try {
            if (this._pending) return;
            this._pending = true;
            var period = Object.keys(this.period).reduce((o, key) => {
              o[key] = this._date(this.period[key]);
              return o;
            }, {});
            var query = [
              `_fees=true`,
              `_refund=true`,
              `date=${this._date(this.date)}`,
              `start=${period.start}`,
              `end=${period.end}`,
              `type=${this.type}`,
              `values=${this.advance ? period.values : this.values}`
            ];
            var r = await Api.get(
              `contracts/${contract._id}?${query.join(`&`)}`
            );
            this._fees(r.data);
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          } finally {
            this._pending = false;
          }
        },
        init() {
          this.date = this._date(moment().format(`L`));
          this.period = this._period();
          this.ref = ``;
          this.show = {
            all: { due: false, fees: false, tiers: false },
            numbers: true
          };
          this.type = contract.billing ? contract.billing.type : this._type();
          this.values = this._values();
          this._text = { NONE: ``, SELF: `Self` };
          this._load();
          return this;
        },
        options(type, d) {
          var format = this._date(`format`);
          var { period } = this;
          switch (type) {
            case `date`:
              return {
                format,
                minDate: data.invoice.date
              };
            case `period`:
              switch (d) {
                case `start`:
                  return {
                    format,
                    minDate: this._date(data.invoice.period.start),
                    maxDate: this._date(this._date(period.end))
                  };
                case `end`:
                  return {
                    format,
                    minDate: this._date(this._date(period.start)),
                    maxDate: this._date(data.invoice.period.end)
                  };
                case `values`:
                  return {
                    format,
                    dates: [data.invoice.period.values].map(d => this._date(d))
                  };
                default:
                  throw new Error(`Invalid ${type} d, ${d}!`);
              }
            case `values`:
              return [`Average Daily`];
            case `type`:
              return [`advance`, `arrears`];
            default:
              throw new Error(`Invalid options type, ${type}!`);
          }
        },
        period: ``,
        ready: ``,
        ref: ``,
        get schedules() {
          return this._schedules;
        },
        show: ``,
        text(type, d, e) {
          var value = (v, f = `$0,0.00`) => numeral(v).format(f);
          switch (type) {
            case `schedule`:
              return `Schedule ${String.fromCharCode(97 + e).toUpperCase()} - ${d.name
                }`;
            case `tier`:
              var start = v => value(v == 0 ? v : v + 1, `$0,0`);
              var end = v => value(v, `$0,0`);
              return e < d.breaks.length - 1
                ? `${start(d.breaks[e])} - ${end(d.breaks[e + 1])}`
                : `> ${start(d.breaks[e])}`;
            case `rate`:
              return `${numeral(d.rates[e]).format(`0,0.[00]`)}%`;
            case `fee`:
            case `split`:
            case `due`:
            case `value`:
              var v = typeof e == `number` ? d[`${type}s`][e] : d[type];
              return type == `fee`
                ? d.owing
                  ? `(*) ${value(v)}`
                  : value(v)
                : value(v);
            case `modal`:
              return `New Invoice - Contract ${Record.id(contract)} (Refund)`;
            case `total`:
              var v, key;
              switch (d) {
                case `due`:
                  v = this.accounts(`due`).reduce((v, a) => (v += a[d]), 0);
                  break;
                case `account-values`:
                case `account-fees`:
                  key = d
                    .split(`-`)
                    .pop()
                    .slice(0, -1);
                  v = this.accounts(`fees`).reduce((v, a) => (v += a[key]), 0);
                  break;
                case `schedule-fees`:
                case `schedule-splits`:
                  key = d.split(`-`).pop();
                  v = e.total[key];
                  break;
                default:
                  throw new Error(`Invalid text ${type} d ${d}!`);
              }
              return value(v);
            case `owing`:
              return d
                ? d.minimum
                  ? `This schedule fees fell short of the pro-rata minimum (${value(
                    d.minimum
                  )} by ${value(d.owing)})}`
                  : ``
                : this.accounts(`fees`).find(o => o.owing)
                  ? `(*) Fee includes a pro-rata amount based on the schedule minimum shortfall`
                  : ``;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        tiers(o) {
          return o.splits.filter(v => v || this.show.all.tiers);
        }
      };
      this.invoice = invoice.init();
    }
  }
};
</script>
