<template>
  <div class="h-100">
    <div class="search">
      <search :o="list"></search>
    </div>
    <div class="d-flex p-1 pb-2 header"
         style="height: 45px">
      <div class="d-flex actions my-auto mr-2">
        <div v-if="!getSelectedHouseholds().length">
          <span class="alt-action text-secondary">Select one or more households to create a group</span>
        </div>
        <div v-else
             title="Create group"
             class="btn btn-outline-secondary"
             v-on:click="createGroup()">
          <i class="fas fa-users mr-1"></i>
          <span>Create group</span>
        </div>
      </div>
      <div class="flex-grow-1 my-auto text-right">
        <span v-text="records()"></span>
      </div>
    </div>
    <template v-if="ready">
      <scroll-area class="pr-3">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>
                <checkbox :o="table"
                          p="selected"
                          v-on:change="selectAll"></checkbox>
              </th>
              <th>
                <span>Household</span>
              </th>
              <th></th>
              <th>
                <span>Contract</span>
              </th>
              <th>
                <span>Date</span>
              </th>
              <th>
                <span>Accounts</span>
              </th>
              <th>
                <span>Value</span>
              </th>
              <th>
                <span>Schedules</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(household, i) in getHouseholds"
                :key="household._id">
              <td>
                <checkbox :o="household"
                          p="selected"
                          :disabled="getHouseholdGroup(household)"></checkbox>
              </td>
              <td>
                <span v-text="household.name"></span>
              </td>
              <td>
                <i v-show="getHouseholdGroup(household)"
                   class="fas fa-users"></i>
              </td>
              <td>
                <a href="#" class="badge badge-custom"
                      v-on:click="openContractModal(household)"
                      v-text="getContractId(household)"></a>
              </td>
              <td style="white-space: nowrap">
                <span v-text="getContractDate(household)"></span>
              </td>
              <td>
                <div style="display: flex; flex-direction: column; gap: 4px">
                  <span v-for="account in getHouseholdAccounts(household)"
                        :key="account._id"
                        v-text="account.number"></span>
                </div>
              </td>
              <td>
                <span v-text="showHouseholdValue(household)"></span>
              </td>
              <td>
                <schedule-badges :schedules="getSchedulesForHousehold(household)"></schedule-badges>
              </td>
            </tr>
          </tbody>
        </table>
      </scroll-area>
    </template>
    <template v-else>
      <loading></loading>
    </template>
  </div>
</template>

<style scoped>
span.alt-action {
  font-weight: 200;
  font-style: italic;
  font-size: 0.8rem;
}

div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}

div.header div.actions,
div.header div.actions div {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.8rem;
}

i {
  opacity: 0.3;
}

i:hover,
i.sort,
i.active {
  cursor: pointer;
  opacity: 1;
}

i.active {
  color: red;
}

span.link {
  cursor: pointer;
  text-decoration: underline;
}

span {
  font-size: 0.9em;
}

span.badge {
  font-weight: normal;
  font-size: 0.8em;
}
</style>

<script>
import { scrollArea, search, checkbox, loading } from "../../../../component";
import { Api, List, Modal, Record } from "../../../../factory";
import { numeral } from "../../../../npm";
import ScheduleBadges from "../../../../component/schedule/schedule-badges.vue";

export default {
  name: `FirmHouseholds`,
  get components() {
    return {
      scrollArea,
      search,
      checkbox,
      loading,
      ScheduleBadges,
    };
  },
  data() {
    return {
      table: {
        selected: ``,
      },
      list: {
        search: ``,
        sort: { asc: ``, key: `` },
      },
      ready: false,
      allHouseholds: [],
      allContracts: [],
      accountsValuesMap: new Map(),
    };
  },
  computed: {
    getHouseholds: function () {
      const { search, sort } = this.list;
      return List.sort(
        List.filter(this.allHouseholds, search),
        sort.key,
        sort.asc
      );
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllHouseholds();
      await this.getAllContracts();
      await this.getAllGroups();

      this.ready = true;
    },
    createGroup() {
      return Modal.open(`group-create`, {
        households: this.getSelectedHouseholds(),
        methods: {
          success: () => {
            Modal.close();
            this.ready = false;
            this.init();
          },
        },
      });
    },
    selectAll() {
      const ids = this.getHouseholds.map((h) => h._id);
      this.allHouseholds.map((h) => {
        if (ids.includes(h._id) && !this.getHouseholdGroup(h)) {
          h.selected = this.table.selected;
        }
        return h;
      });
    },
    records() {
      return List.records(this.getHouseholds, this.allHouseholds);
    },
    async getAllHouseholds() {
      this.allHouseholds = (await Api.get(`households`)).data.map((h) => ({
        ...h,
        selected: ``,
      }));
    },
    async getAllContracts() {
      const contractValues = (await Api.get(`contracts/list-with-values`)).data;
      this.allContracts = contractValues.contracts;
      // console.log(contractValues);
      //remove duplicated records from accountsWithValues, by keeping the last ticker only
      contractValues.accountsWithValues.forEach((acc) =>
        this.accountsValuesMap.set(acc.number, acc.value),
      );
      this.allHouseholds.sort(
        (a, b) => this.getHouseholdValue(b) - this.getHouseholdValue(a)
      );
    },
    async getAllGroups() {
      this.allGroups = (await Api.get(`groups`)).data;
    },
    getSelectedHouseholds() {
      return this.getHouseholds.filter((h) => h.selected);
    },
    getActiveContractFromHousehold(household) {
      return this.allContracts.find(
        (c) => c.householdId == household._id && c.status == 2
      );
    },
    getScheduleForAccount(contract, account) {
      return contract.schedules.find(s => s._id == account.scheduleId);
    },
    getSchedulesInContracts() {
      const schedules = [];
      this.allContracts.forEach((c) => {
        c.accounts.forEach((acc) => {
          const schedule = c.schedules.find(s => s._id == acc.scheduleId);
          if (!schedules.some((s) => s.name == schedule.name))
            schedules.push(schedule);
        });
      });
      return schedules;
    },
    getContractId(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? Record.id(contract) : "";
    },
    getSchedulesForHousehold(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.schedules : [];
    },
    getContractDate(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.date : "-----";
    },
    getContractRef(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.ref : "-----";
    },
    getHouseholdAccounts(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.accounts : [];
    },
    openContractModal(household) {
      const contract = this.getActiveContractFromHousehold(household);
      if (contract)
        Modal.open(`contract`, {
          contract,
          methods: {
            success: () => {
              Modal.close();
              this.getAllContracts();
            },
          },
        });
    },
    getHouseholdValue(household) {
      // console.log("household", household);
      const accounts = this.getHouseholdAccounts(household);

      return accounts.reduce(
        (total, acc) => this.accountsValuesMap.get(acc.number) + total,
        0
      );
    },
    showHouseholdValue(household) {
      const totalValue = this.getHouseholdValue(household);

      return numeral(totalValue).format(`$0,0.00`);
    },
    getHouseholdGroup(household) {
      return this.allGroups.find((g) => g.householdIds.includes(household._id));
    },
  },
};
</script>
