
<div class="modal-dialog modal-dialog-centered modal-xl"
     role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-file-alt mr-2"></i>
        <span class="font-weight-bold"
              v-text="nav.title(`modal`)"></span>
      </p>
    </div>
    <div class="modal-body p-2">
      <ul class="nav">
        <li v-for="(item, index) in nav.items"
            :key="index"
            class="nav-item mx-1"
            :class="nav.class(`nav`, item)">
          <a class="nav-link"
             v-on:click="nav.click(`nav`, item)">
            <span v-text="item"></span>
          </a>
        </li>
      </ul>
      <div class="component pl-2 pt-3">
        <component :is="nav.component"
                   :contract="contract"></component>
      </div>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <remove-button :button="nav.discard"></remove-button>
        <button type="button"
                class="btn btn-sm btn-primary ml-2"
                :disabled="nav.disabled(`submit`)"
                v-on:click="nav.click(`submit`)">
          <span>Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
