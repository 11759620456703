<template>
  <div class="h-custom">
    <hr class="mt-0 ml-2 mr-3 mb-2" />
    <scroll-area class="pl-2 pr-3 mb-2">
      <div class="form-row">
        <div class="col">
          <label>Date</label>
          <date-picker :clearable="false"
                       style="display: block;"
                       v-model="contract.date"
                       format="MMM D, YYYY"></date-picker>
          <small class="form-text text-muted">Amend Contract Date</small>
        </div>
        <div class="col">
          <label>Reference</label>
          <input v-model="contract.ref"
                 class="form-control form-control-sm"
                 placeholder="Reference" />
          <small class="form-text text-muted">Contract Reference</small>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <label>Billing</label>
          <div class="form-row">
            <div class="col">
              <select v-model="contract.billing.type"
                      class="form-control text-capitalize form-control-sm">
                <option class="text-capitalize"
                        v-for="(o, i) in summary.options(`billing`, `type`)"
                        :key="i">
                  <span class="text-capitalize"
                        v-text="o"></span>
                </option>
              </select>
              <small class="form-text text-muted">Billing Type</small>
            </div>
            <div class="col">
              <select v-model="contract.billing.schedule"
                      class="form-control form-control-sm">
                <option v-for="(o, i) in summary.options(`billing`, `schedule`)"
                        :key="i">
                  <span v-text="o"></span>
                </option>
              </select>
              <small class="form-text text-muted">Billing Schedule</small>
            </div>
            <div class="col">
              <select v-model="contract.billing.values"
                      class="form-control form-control-sm"
                      :disabled="summary.disabled(`billing`, `values`)">
                <option v-for="(o, i) in summary.options(`billing`, `values`)"
                        :key="i">
                  <span v-text="o"></span>
                </option>
              </select>
              <small class="form-text text-muted">Billing Values</small>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div v-for="(s, index) in contract.schedules"
           :key="index"
           class="row">
        <div class="col mt-2">
          <div class="d-flex">
            <div>
              <u class="pl-2"
                 v-text="summary.text(`schedule`, index)"></u>
            </div>
            <div class="flex-grow-1 text-right">
              <template v-if="!index">
                <span class="mr-2">Obfuscate account numbers</span>
                <checkbox :o="contract.show"
                          p="numbers"
                          title="Toggle obfuscate account numbers"></checkbox>
              </template>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <div class="row">
                <div class="col">
                  <span class="title pl-2"
                        v-text="summary.text(`schedule`, s)"></span>
                </div>
              </div>
              <div class="row py-3">
                <div class="col">
                  <table class="table table-sm schedule mb-1">
                    <thead>
                      <tr>
                        <th>
                          <span>Range</span>
                        </th>
                        <th>
                          <span>Rate</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(t, i) in summary.tiers(s)"
                          :key="i">
                        <td>
                          <span v-text="t.range"></span>
                        </td>
                        <td>
                          <span v-text="t.rate"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <template v-if="s.minimum">
                    <hr class="my-1" />
                    <span class="font-italic ml-1"
                          v-text="summary.text(`minimum`, s)"></span>
                  </template>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <span class="title pl-2">Accounts</span>
                </div>
              </div>
              <div class="row py-3">
                <div class="col">
                  <table class="table table-sm accounts">
                    <thead>
                      <tr>
                        <th>
                          <span>Number</span>
                        </th>
                        <th>
                          <span>Billing</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(a, i) in summary.accounts(s)"
                          :key="i">
                        <td>
                          <span v-text="summary.text(`number`, a)"></span>
                        </td>
                        <td>
                          <span v-text="a.billingNumber ? a.billingNumber : a.name"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="summary.exceptions.length">
        <u class="pl-2">Exceptions</u>
        <div class="mt-3">
          <table class="table table-sm exceptions">
            <thead>
              <tr>
                <th>
                  <span>Type</span>
                </th>
                <th>
                  <span>Account</span>
                </th>
                <th>
                  <span>Schedule</span>
                </th>
                <th>
                  <span>Ticker</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(o, i) in summary.exceptions"
                  :key="i">
                <td>
                  <span v-text="o.typeSelected"></span>
                </td>
                <td>
                  <span v-text="summary.text(`accountSelected`, o)"></span>
                </td>
                <td>
                  <span v-text="o.scheduleName"></span>
                </td>
                <td>
                  <span v-text="summary.text(`ticker`, o)"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <contract-transactions-creation-table v-if="contract.transactionsRules.length"
                                            :contractAccounts="contract.accounts"
                                            v-model="contract.transactionsRules"
                                            readonly />

      <div v-if="summary.lineItems.length">
        <u class="pl-2">Line Items</u>
        <div class="mt-3">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>
                  <span>Description</span>
                </th>
                <th>
                  <span>Account</span>
                </th>
                <th>
                  <span>Timing</span>
                </th>
                <th>
                  <span>Amount</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(o, i) in summary.lineItems"
                  :key="i">
                <td>
                  <span v-text="o.description"></span>
                </td>
                <td>
                  <span v-text="o.account"></span>
                </td>
                <td>
                  <span v-text="o.timing"></span>
                </td>
                <td>
                  <span v-text="summary.text(`amount`, o.amount)"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col mt-2">
          <u class="pl-2">Signees</u>
        </div>
      </div>
      <template v-if="summary.signees.length">
        <div class="row py-3">
          <div class="col">
            <table class="table table-sm signees">
              <thead>
                <tr>
                  <th>
                    <span>Order</span>
                  </th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Email</span>
                  </th>
                  <th>
                    <span>Role</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(s, i) in summary.signees"
                    :key="i">
                  <td>
                    <span v-text="summary.text(`signees`, i)"></span>
                  </td>
                  <td>
                    <span v-text="s.name"></span>
                  </td>
                  <td>
                    <span v-text="s.email"></span>
                  </td>
                  <td>
                    <span class="text-capitalize"
                          v-text="s.role"></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="font-weight-bold font-italic text-center no-signees m-3"
           v-text="summary.text(`signees`)"></p>
      </template>
      <hr />
    </scroll-area>
  </div>
</template>

<style scoped>
div.amenddate {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}

div.options {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
}

div.ref {
  width: 17em;
  margin-right: 1em;
}

div.date {
  width: 12em;
}

td span {
  text-transform: capitalize;
}

span {
  font-size: 0.9em;
}

p.no-signees {
  opacity: 0.3;
}

u {
  font-weight: 600;
}

span.title {
  font-size: 1em;
}

div.h-custom {
  height: calc(100% - 0.8em);
}
</style>

<script>
import { checkbox, scrollArea } from "../../../../../../../component";
import { Contract } from "../../../../../../../factory";
import { moment, numeral, ordinal } from "../../../../../../../npm";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ContractTransactionsCreationTable from "../../../../../../../component/transactions/contract-transactions-creation-table.vue";
import { startCase } from 'lodash';

export default {
  get components() {
    return {
      checkbox,
      DatePicker,
      scrollArea, ContractTransactionsCreationTable
    };
  },
  props: {
    contract: {
      type: Object,
      required: true,
      validator: (o) => Array.isArray(o.accounts) &&
        Array.isArray(o.exceptions) &&
        Array.isArray(o.lineItems) &&
        Array.isArray(o.signees) &&
        typeof o.data == `object` &&
        typeof o.state == `object`,
    },
  },
  data() {
    return {
      summary: ``,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { contract } = this;
      var summary = {
        _alerts() {
          var [active] = this.options(`alerts`);
          var cash = { value: 0, percent: 0 };
          var positions = { value: 0, percent: 0 };
          return { active, cash, positions };
        },
        _billing() {
          var [, schedule] = this.options(`billing`, `schedule`);
          var [type] = this.options(`billing`, `type`);
          var [values] = this.options(`billing`, `values`);
          return { schedule, type, values };
        },
        _init: (...args) => this.$set(...args),
        _ref() {
          var [household] = contract.data.households;
          var q = moment().quarter();
          return `${household.name} - ${q}`;
        },
        _schedules() {
          return Array.from([...contract.accounts.filter(o => o.select), ...this.exceptions]
          .reduce((s, o) => (s.has(o.scheduleName) ? s : s.add(o.scheduleName)), 
          new Set())).map((s) => contract.data.schedules.find((o) => o.name == s));
        },
        _template() {
          return this.options(`template`)[0];
        },
        _text: { NONE: `` },
        accounts(s) {
          var { NONE } = this._text;
          return [
            ...contract.accounts.filter((o) => o.scheduleName == s.name && o.select),
            ...this.exceptions
              .filter((o) => o.scheduleName == s.name)
              .map((o) => {
                var number = o.accountSelected.toLowerCase().includes(`all`)
                  ? NONE
                  : o.accountSelected.split(`)`).shift().split(`(`).pop();
                var name = number == NONE
                  ? o.accountSelected
                  : o.accountSelected.split(`)`).pop().trim();
                name += o.ticker.symbol
                  ? o.type == 'position' ? ` (Ticker: ${o.ticker.symbol})` : ` (${startCase(o.type)})` 
                  : ` (Cash)`;
                var billing = number == NONE
                  ? NONE
                  : contract.accounts.find((a) => o.accountSelected.includes(a.number)).billing;
                return { billing, name, number };
              }),
          ];
        },
        change(type) {
          switch (type) {
            case `alerts`:
              Object.keys(contract.alerts).forEach((key) => {
                switch (key) {
                  case `cash`:
                  case `positions`:
                    contract.alerts[key] = {
                      value: numeral(contract.alerts[key].value).format(`0,00.00`),
                      percent: numeral(contract.alerts[key].percent).format(`0.[00]`),
                    };
                    break;
                }
              });
              break;
          }
        },
        disabled(type, d) {
          switch (type) {
            case `alerts`:
              return contract.alerts.active.toLowerCase() == `disabled`;
            case `billing`:
              switch (d) {
                case `values`:
                  return this.options(`billing`, `values`).length == 1;
                default:
                  throw new Error(`Invalid disabled ${type} data, ${d}!`);
              }
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init() {
          if (!contract.hasOwnProperty(`schedules`))
            this._init(contract, `schedules`, ``);
          contract.schedules = this._schedules();
          if (!contract.hasOwnProperty(`ref`))
            this._init(contract, `ref`, this._ref());
          if (!contract.hasOwnProperty(`template`))
            this._init(contract, `template`, this._template());
          if (!contract.hasOwnProperty(`billing`))
            this._init(contract, `billing`, this._billing());
          // if (!contract.hasOwnProperty(`alerts`))
          //   this._init(contract, `alerts`, this._alerts());
          if (!contract.hasOwnProperty(`show`))
            this._init(contract, `show`, { numbers: true });
          this._text = { NONE: `-` };
          return this;
        },
        get exceptions() {
          return Contract.exceptions(contract);
        },
        get lineItems() {
          return contract.lineItems;
        },
        options(type, d) {
          switch (type) {
            case `alerts`:
              return [`Disabled`, `Enabled`];
            case `billing`:
              switch (d) {
                case `schedule`:
                  // check if we have montly/quarter/annual line items
                  if (contract.lineItems.find(l => l.timing == `Month`))
                    return [`Monthly`]
                  else if (contract.lineItems.find(l => l.timing == `Quarter`))
                    return [`Quarterly`];
                  else if (contract.lineItems.find(l => l.timing == `Annual`))
                    return [`Annually`];
                  else return [`Monthly`, `Quarterly`, `Annually`];
                case `type`:
                  return [`advance`, `arrears`];
                case `values`:
                  var values = [`Latest`,`Average Daily`]
                  if (contract.billing &&
                    values.indexOf(contract.billing.values) < 0)
                    contract.billing.values = values[0];
                  return values;
                default:
                  throw new Error(`Invalid options ${type} data, ${d}!`);
              }
            case `template`:
              return [`Default`];
            default:
              throw new Error(`Invalid options type, ${type}!`);
          }
        },
        get signees() {
          return Contract.signees(contract);
        },
        text(type, d) {
          var number = (n) => contract.show.numbers
            ? [
              ...new Array(Math.floor(n.length / 2)).fill(`*`),
              n.slice(Math.floor(n.length / 2)),
            ].join(``)
            : n;
          switch (type) {
            case `amount`:
              return numeral(d).format(`$0,0.00`);
            case `accountSelected`:
              if (!d[type]?.includes(`(`))
                return d[type];
              if (!d.account?.includes(`(`))
                return d[type];
              var [a, b] = d[type].split(`)`);
              var n = number(a.split(`(`).pop());
              return `(${n}) ${b.trim()}`;
            case `number`:
              return d[type].length > 1 ? number(d[type]) : d[type];
            case `minimum`:
              return `Minimum Annual Fee: ${numeral(d.minimum).format(`$0,0.[00]`)}`;
            case `schedule`:
              return typeof d == `number`
                ? `Schedule ${String.fromCharCode(97 + d).toUpperCase()}`
                : d.name;
            case `signees`:
              return typeof d == `number`
                ? ordinal(d + 1)
                : `This contract has electronic signing disabled and requires no signatures!`;
            case `ticker`:
              return d.ticker.symbol
                ? `${d.ticker.symbol} ${d.ticker.name ? `(${d.ticker.name})` : ``}`
                : `-`;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        tiers(s) {
          var format = {
            range: (v) => numeral(v).format(`$0,0`),
            rate: (v) => numeral(v).format(`0.[00]%`),
          };
          return s.breaks.map((b, i) => {
            var range;
            if (s.breaks.length > 1) {
              var start = i ? b + 1 : b;
              var end = i < s.breaks.length - 1 ? s.breaks[i + 1] : 0;
              range = end
                ? `${format.range(start)} - ${format.range(end)}`
                : `> ${format.range(start - 1)}`;
            }
            else {
              range = `> ${format.range(b)}`;
            }
            var rate = format.rate(s.rates[i]);
            return { range, rate };
          });
        },
      };
      this.summary = summary.init();
    },
  },
};
</script>
