<template>
  <div class="modal-dialog modal-dialog-centered modal-xl"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-file-alt mr-2"></i>
          <span class="font-weight-bold"
                v-text="invoice.text(`modal`)"></span>
        </p>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <template v-if="invoice.ready">
          <scroll-area class="px-4 py-3">
            <div class="form-row">
              <div class="col-12">
                <p class="font-weight-bold">Invoice</p>
                <div class="form-row">
                  <div class="col-3">
                    <div class="form-group">
                      <date-picker :o="invoice"
                                   p="date"
                                   :options="invoice.options(`date`)"></date-picker>
                      <small class="form-text text-muted">Invoice Date</small>
                    </div>
                  </div>
                  <div class="col-3">
                    <select v-model="invoice.type"
                            class="form-control form-control-sm text-capitalize"
                            disabled>
                      <option class="text-capitalize"
                              v-for="(o, i) in invoice.options(`type`)"
                              :key="i">
                        <span class="text-capitalize"
                              v-text="o"></span>
                      </option>
                    </select>
                    <small class="form-text text-muted">Invoice Type</small>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="font-weight-bold">Period</p>
                <div class="form-row">
                  <div class="col-3">
                      <select v-model="invoice.values"
                              class="form-control form-control-sm"
                              disabled>
                        <option v-for="(o, i) in invoice.options(`period`, `values`)"
                                :key="i">
                          <span v-text="o"></span>
                        </option>
                      </select>
                    <small class="form-text text-muted">Period Values</small>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <date-picker :o="invoice.period"
                                   p="start"
                                   :options="invoice.options(`period`, `start`)"></date-picker>
                      <small class="form-text text-muted">Period Start</small>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <date-picker :o="invoice.period"
                                   p="end"
                                   :options="invoice.options(`period`, `end`)"></date-picker>
                      <small class="form-text text-muted">Period End</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="d-flex">
              <div>
                <p class="font-weight-bold"
                   v-text="invoice.text(`accounts`, `due`)"></p>
              </div>
              <div class="flex-grow-1 text-right">
                <span class="mr-2">Obfuscate account numbers</span>
                <checkbox :o="invoice.show"
                          p="numbers"
                          title="Toggle obfuscate account numbers"></checkbox>
              </div>
              <div class="text-right ml-4">
                <span class="mr-2">Show all accounts</span>
                <checkbox :o="invoice.show.all"
                          p="due"
                          title="Toggle all accounts"></checkbox>
              </div>
            </div>
            <table class="table table-sm accounts mb-5">
              <thead>
                <tr>
                  <th>
                    <span>Number</span>
                  </th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Household</span>
                  </th>
                  <th class="text-right">
                    <span>Billing</span>
                  </th>
                  <th class="text-right">
                    <span>Amount</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(o, i) in invoice.accounts(`due`)"
                    :key="i">
                  <td>
                    <span v-text="o.number"></span>
                  </td>
                  <td>
                    <span v-text="o.name"></span>
                  </td>
                  <td>
                    <span v-text="o.household"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="o.billing"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`due`, o)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3"></td>
                  <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right">
                    <span class="total"
                          v-text="invoice.text(`total`, `due`)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <hr />
            <div class="d-flex">
              <div>
                <p class="font-weight-bold">Account Fees</p>
              </div>
              <div class="flex-grow-1 text-right">
                <span class="mr-2">Show all fees</span>
                <checkbox :o="invoice.show.all"
                          p="fees"
                          title="Toggle all fees"></checkbox>
              </div>
            </div>
            <table class="table table-sm accounts mb-0">
              <thead>
                <tr>
                  <th>
                    <span>Number</span>
                  </th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Schedule</span>
                  </th>
                  <th class="text-right">
                    <span>Value</span>
                  </th>
                  <th class="text-right">
                    <span>Fee</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(o, i) in invoice.accounts(`fees`)"
                    :key="i">
                  <td>
                    <span v-text="o.number"></span>
                  </td>
                  <td>
                    <span v-text="o.name"></span>
                    <small v-if="o.proratedDays" class="text-muted small">(pro-rated for {{ o.proratedDays }} days)</small>
                  </td>
                  <td>
                    <span v-text="o.schedule"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`value`, o)"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`fee`, o)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"></td>
                  <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right">
                    <span class="total"
                          v-text="invoice.text(`total`, `account-values`)"></span>
                  </td>
                  <td class="text-right">
                    <span class="total"
                          v-text="invoice.text(`total`, `account-fees`)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <p class="text-right pr-3">
              <small class="text-muted"
                     v-text="invoice.text(`owing`)"></small>
            </p>
            <hr />
            <div style="padding-bottom: 0.5rem">
              <div class="d-flex">
                <span class="font-weight-bold">Schedules</span>
                <div class="flex-grow-1 text-right">
                  <span class="mr-2">Show all tiers</span>
                  <checkbox :o="invoice.show.all"
                            p="tiers"
                            title="Toggle all tiers"></checkbox>
                </div>
              </div>

              <span class="text-secondary"
                    v-if="invoice.group">
                <small>
                  These schedules are applied in a group. Exceptions are 
                  shared among members of a group.
                </small>
              </span>
            </div>
            <div v-for="(o, index) in invoice.schedules"
                 :key="index">
              <hr class="mt-0" />
              <p class="schedule"
                 v-text="invoice.text(`schedule`, o, index)"></p>
              <table class="table table-sm schedule mb-4">
                <thead>
                  <tr>
                    <th>
                      <span>Tier</span>
                    </th>
                    <th class="text-right">
                      <span>Rate</span>
                    </th>
                    <th v-if="invoice.group"
                        class="text-right">
                      <span>Applied group rate</span>
                    </th>
                    <th></th>
                    <th class="text-right">
                      <span>Value</span>
                    </th>
                    <th class="text-right">
                      <span>Fee</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(split, i) of invoice.tiers(o)"
                      :key="i">
                    <td>
                      <span v-text="invoice.text(`tier`, o, i)"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="invoice.text(`rate`, o, i)"></span>
                    </td>
                    <td v-if="invoice.group"
                        class="text-right">
                      <span v-text="invoice.text(`group-rate`, o, i)"></span>
                    </td>
                    <td></td>
                    <td class="text-right">
                      <span v-text="invoice.text(`split`, o, i)"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="invoice.text(`fee`, o, i)"></span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2"></td>

                    <td v-if="invoice.group"></td>
                    <td class="text-right">
                      <span class="font-weight-bold">Total</span>
                    </td>
                    <td class="text-right">
                      <span class="total"
                            v-text="invoice.text(`total`, `schedule-splits`, o)"></span>
                    </td>
                    <td class="text-right">
                      <span class="total"
                            v-text="invoice.text(`total`, `schedule-fees`, o)"></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <template v-if="o.owing">
                <p class="text-right">
                  <small class="text-muted"
                         v-text="invoice.text(`owing`, o)"></small>
                </p>
              </template>
            </div>

            <div v-if="invoice.lineItems.length"
                 class="mb-4">
              <p class="text-right pr-3">
                <small class="text-muted"></small>
              </p>
              <hr />
              <p class="font-weight-bold">Line Items</p>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th>
                      <span>Description</span>
                    </th>
                    <th>
                      <span>Account</span>
                    </th>
                    <th>
                      <span>Timing</span>
                    </th>

                    <th>
                      <span>Amount</span>
                    </th>
                    <th class="text-center">
                      <span>Due</span>
                      &nbsp;<i class="far fa-question-circle" 
                      title="Due amount only shows up for 'Annual' line items."></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(o, i) in invoice.lineItems"
                      :key="i">
                    <td>
                      <span v-text="o.description"></span>
                    </td>
                    <td>
                      <span v-text="invoice.text(`account`, o)"></span>
                    </td>
                    <td>
                      <span v-text="o.timing"></span>
                    </td>
                    <td>
                      <span v-text="invoice.text(`amount`, o)"></span>
                    </td>
                    <td  class="text-center">
                      <span v-if="o.timing.toLowerCase() == `annual`">
                      {{ invoice.text(`amount-due`, o) }}</span>
                      <span v-else>N/A</span>
                    </td>
                   </tr>
                </tbody>
              </table>
            </div>

            <template v-if="invoice.lastInvoice">
              <div>
                <div class="d-flex">
                  <div>
                    <p class="font-weight-bold">Adjustments</p>
                  </div>
                </div>

                <hr class="mt-0" />
                <p class="schedule">
                  {{ invoice.display_date(invoice.lastInvoice.period.start) }} -
                  {{ invoice.display_date(invoice.lastInvoice.period.end) }}
                </p>
              </div>
            </template>
            <template v-if="invoice.accountsChanges">
              <div>
                <hr class="mt-0" />
                <p class="schedule">Accounts Adjustments</p>
                <table class="table table-sm accounts mb-4">
                  <thead>
                    <tr>
                      <th>
                        <span>Number</span>
                      </th>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Schedule</span>
                      </th>
                      <th class="text-right">
                        <span>Change</span>
                      </th>
                      <th class="text-right">
                        <span>Adjustment</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ac, i) of invoice.accountsChanges"
                        :key="i">
                      <td>
                        <span v-text="ac.number"></span>
                      </td>
                      <td>
                        <span v-text="invoice.text(`account-name`, ac)"></span>
                      </td>
                      <td>
                        <span v-text="invoice.text(`schedule-name`, ac)"></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span v-text="ac.change_f"></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span v-text="ac.adjustment_f"></span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2"></td>
                      <td class="text-right">
                        <span class="font-weight-bold">Total</span>
                      </td>
                      <td class="text-right no-wrap">
                        <span class="total"
                              v-text="
                                invoice.text(`total`, `accounts-changes-change`)
                              "></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span class="total"
                              v-text="
                                invoice.text(`total`, `accounts-changes-adjustment`)
                              "></span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </template>
            <template v-else>
              <div></div>
            </template>

            <template v-if="invoice.schedulesChanges">
              <div>
                <hr class="mt-0" />
                <p class="schedule">Schedules changes</p>
                <table class="table table-sm mb-4">
                  <thead>
                    <tr>
                      <th>
                        <span>Schedule</span>
                      </th>

                      <th class="text-right"></th>
                      <th style="width: 12%"
                          class="text-right">
                        <span>Change</span>
                      </th>
                      <th style="width: 12%"
                          class="text-right">
                        <span>Adjustment</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sc, i) of invoice.schedulesChanges"
                        :key="i">
                      <td>
                        <span v-text="sc.name"></span>
                      </td>
                      <td></td>
                      <td class="text-right no-wrap">
                        <span v-text="sc.change_f"></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span v-text="sc.adjustment_f"></span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td class="text-right no-wrap">
                        <span class="font-weight-bold">Total</span>
                      </td>
                      <td class="text-right no-wrap">
                        <span class="total"
                              v-text="
                                invoice.text(`total`, `schedules-changes-change`)
                              "></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span class="total"
                              v-text="
                                invoice.text(
                                  `total`,
                                  `schedules-changes-adjustment`
                                )
                              "></span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </template>
            <template v-else>
              <div></div>
            </template>

            <!-- <template v-if="invoice.exceptionsChanges">
              <div>
                <hr class="mt-0" />
                <p class="schedule">Exceptions changes</p>
                <table class="table table-sm schedule-changes mb-4">
                  <thead>
                    <tr>
                      <th>
                        <span>Exceptions</span>
                      </th>
                      <th class="text-right">
                        <span>Change</span>
                      </th>
                      <th class="text-right">
                        <span>Adjustment</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sc, i) of invoice.exceptionsChanges" :key="i">
                      <td>
                        <span v-text="sc.name"></span>
                      </td>
                      <td class="text-right">
                        <span v-text="sc.change_f"></span>
                      </td>
                      <td class="text-right">
                        <span v-text="sc.adjustment_f"></span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-right">
                        <span class="font-weight-bold">Total</span>
                      </td>
                      <td class="text-right">
                        <span
                          class="total"
                          v-text="
                            invoice.text(`total`, `schedules-changes-change`)
                          "
                        ></span>
                      </td>
                      <td class="text-right">
                        <span
                          class="total"
                          v-text="
                            invoice.text(
                              `total`,
                              `schedules-changes-adjustment`
                            )
                          "
                        ></span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </template>
            <template v-else>
              <div></div>
            </template>-->
            <rebated-transactions :transactions="invoice.transactions"
                                  v-if="invoice.transactions && invoice.transactions.length > 0"
                                  :obfuscateNumbers="invoice.show.numbers"></rebated-transactions>

            <div class="d-flex">
              <div>
                <p class="font-weight-bold">Grand total</p>
              </div>
            </div>

            <table class="table table-sm mb-4">
              <thead>
                <tr>
                  <th style="width: 8%; white-space: nowrap">
                    <span>Account</span>
                  </th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th></th>
                  <th class="text-right">
                    <span>Amount</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ac, i) of invoice.accounts(`grandtotal`)"
                    :key="i">
                  <td style="white-space: nowrap">
                    <span v-text="ac.number"></span>
                  </td>
                  <td>
                    <span v-text="ac.name"></span>
                  </td>
                  <td :set="nTotal = invoice.getAccountGrandTotal(ac)">
                    <span v-if="nTotal < 0">
                      <em>(Carryover {{value(nTotal * -1) }})</em>
                    </span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`account-grand-total`, ac)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right"
                      style="width: 65px">
                    <span class="total"
                          v-text="invoice.text(`total`, `account-grand-total`)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </scroll-area>
        </template>
        <template v-else>
          <loading></loading>
        </template>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <remove-button :button="invoice.remove"></remove-button>
          <!-- <button
            type="button"
            class="btn btn-sm btn-primary ml-2"
            :disabled="invoice.disabled(`export`)"
            v-on:click="invoice.click(`export`)"
          >
            <span class="button">Export</span>
          </button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h3 {
  opacity: 0.5;
}

span.multi {
  font-size: 0.8em;
  opacity: 0.7;
  font-style: italic;
}

small {
  padding-left: 0.7em;
  margin-top: 0.1em;
}

div.menu {
  height: 2.5em;
}

div.controls {
  width: 15em;
}

span.date {
  font-size: 0.7em;
}

button {
  width: 80px;
}

div.modal-body {
  height: calc(100vh - 12em);
}

div.accounts div.form-group {
  margin-bottom: 0.5em;
}

p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}

table.accounts th:nth-child(1) {
  width: 8%;
}

table.accounts th:nth-child(2) {
  width: 50%;
}

table.accounts th:nth-last-child(2) {
  width: 12%;
}

table.accounts th:nth-last-child(1) {
  width: 12%;
}

table.schedule th:nth-child(1) {
  width: 25%;
}

table.schedule th:nth-child(2) {
  width: 15%;
}

table.schedule th:nth-last-child(2) {
  width: 12%;
}

table.schedule th:nth-last-child(1) {
  width: 12%;
}

span.total {
  font-weight: 600;
}

span.title {
  font-size: 1em !important;
}

p.schedule {
  font-weight: 500;
}

span:not(.button) {
  font-size: 0.9em;
}
</style>

<script>
import {
  checkbox,
  datePicker,
  loading,
  removeButton,
  scrollArea,
} from "../../../../component";
import { Api, lib, Modal, Record } from "../../../../factory";
import { moment, numeral } from "../../../../npm";
import { alert, session } from "../../../../service";
import { calculateLineItemAmount } from "../../../../service/invoice_period";
import RebatedTransactions from "../../clients/modal/invoice/rebated-transactions.vue";

export default {
  get components() {
    return {
      checkbox,
      datePicker,
      loading,
      removeButton,
      RebatedTransactions,
      scrollArea,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (o) => typeof o.invoice == `object`,
    },
  },
  data() {
    return {
      invoice: ``,
    };
  },
  created() {
    this.init();
  },
  methods: {
    value(v, f = `$0,0.00`) { return numeral(v).format(f) },
    init() {
      const state = session.get(`state`);
      var { data } = this;
      var invoice = {
        display_date(d) {
          var f = `MMM D, YYYY`;
          return moment(d).format(f);
        },
        _account(o) {
          var a = this._data.accounts.find((a) => a.number == o.number);
          var { name, number, fundedDate } = a;
          var { name: household } = this._data.household;
          var { name: schedule } = this._data.contract.schedules.find(s => s._id == o.scheduleId);
          var billing; 
          if (o.billing == -1) billing = this._text.SELF
          else if (o.billing == 99) billing = this._text.BTI
          else billing =  data.invoice.accounts.find(ac => ac._id == o.billing).number 
          var due = 0;
          var fee = 0;
          var owing = 0;
          var value = 0;
          var proratedDays = fundedDate && !this.isAdvance ? this.updateProratedDays(fundedDate) : null;
          return {
            billing,
            due,
            fee,
            household,
            name,
            number,
            owing,
            value,
            schedule,
            proratedDays,
          };
        },        _accounts: ``,
        group: ``,
        _date(d) {
          var f = `MMM D, YYYY`;
          var { dates } = this._data;
          switch (d) {
            case `format`:
              return f;
            case `start`:
              return this.isAdvance
                ? this._date(moment().format(`L`))
                : this._date(dates[0]);
            case `end`:
              return this.isAdvance
                ? this._date(moment().add(3, `months`).format(`L`))
                : this._date(dates[dates.length - 1]);
            default:
              if (typeof d == `string`)
                return d.includes(`/`)
                  ? moment(d, `L`).format(f)
                  : moment(d, f).format(`L`);
              else 
                return { 
                  start: moment(d.start, `L`).format(f), 
                  end: moment(d.end, `L`).format(f) 
                }
          }
        },
        _data: { accounts: ``, contract: ``, household: `` },
        _export() {
          alert.warning(`Coming soon!`, 3000);
        },
        accountsChanges: ``,
        schedulesChanges: ``,
        exceptionsChanges: ``,
        lastInvoice: ``,
        adjustmentContract: ``,
        async lastInvoiceFees(adjustments) {
          if (adjustments?.accountsChanges?.length)
            this.accountsChanges = adjustments?.accountsChanges;
          if (adjustments?.schedulesChanges?.length)
            this.schedulesChanges = adjustments?.schedulesChanges;
          if (adjustments?.exceptionsChanges?.length)
            this.exceptionsChanges = adjustments?.exceptionsChanges;
          if (adjustments?.lastInvoice) {
            this.lastInvoice = adjustments?.lastInvoice;
            if (!adjustments?.contract) {
              adjustments.contract = (await Api.get(`contracts?_id=${this.lastInvoice.contractId}`)).data;
            }
          }
          if (adjustments?.contract) {
            this.adjustmentContract = adjustments?.contract;
            const res = await Promise.all(this.adjustmentContract.accounts.map((o) => Api.get(`accounts?number=${o.number}`)));
            const accounts = res.map((r) => r.data[0]);
            this.adjustmentContract.accounts.forEach((account) => {
              account.name =
                accounts.find((acc) => acc.number == account.number)?.name ??
                "";
            });
          }
        },
        transactions: [],
        async _fees(d) {
          /*
          this.transactions = d.accounts.reduce((acc, account) => {
            if (account.rebatedTransactions?.length) {
              return acc.concat(account.rebatedTransactions);
            }
            return acc;
          }, []);
          */
          await this.lastInvoiceFees(d.adjustments);
          this._accounts.forEach((a) => {
            var account = d.accounts.find((o) => o.number == a.number);
            a.value = account.value;
            a.due = account.due;
            a.fee = account.fee;
            a.owing = account.owing;

            // Update proratedDays
            if (a.fundedDate)
              a.proratedDays = this.updateProratedDays(a.fundedDate);

          });
          this.schedules.forEach((s, i) => {
            var schedule = d.schedules[i];
            s.minimum = schedule.minimum;
            s.splits = [...schedule.splits];
            s.fees = [...schedule.fees];
            s.owing = schedule.owing;
            s.total = schedule.total;
            // if thresholdBilling is enabled the `breaks` and `rates`
            // were reduced on the backend and we'll use those new ones
            // not the default one we got from contract.schedules.[rates/breaks]
            if (state.firm.features.thresholdBilling) {
                s.breaks = schedule.breaks
                s.rates  = schedule.rates
            }
          });
        },
        _init: (...args) => this.$set(...args),
        async _load() {
          try {
            if (this._pending)
              return;
            this._pending = true;
            var { data: contract } = await Api.get(`contracts/${data.invoice.contractId}?_carry=false`);
            this._data.contract = contract;
            var accounts = (await Promise.all(data.invoice.accounts.map((a) => Api.get(`accounts?number=${a.number}`)))).map((r) => r.data[0]);
            this._data.accounts = accounts;
            var { data: household } = await Api.get(`households/${contract.householdId}`);
            this._data.household = household;
            const [group] = (await Api.get(`groups?householdIds=${household._id}`)).data;
            this.group = group;
            this._accounts = contract.accounts.map((o) => this._account(o));
            this._schedules = contract.schedules.map((o) => this._schedule(o));
            await this._fees(data.invoice);
            this.lineItems = contract.lineItems;
            this.ready = true;

            // initate the values here because we'll need to pull the contract first 
            // (v-model related for period values)
            this.values = this._values();
          }
          catch (e) {
            console.error(e);
            alert.error(e.message);
          }
          finally {
            this._pending = false;
          }
        },
        lineItems: ``,
        _pending: ``,
        _period() {
          return [`start`, `end`, `values`].reduce((o, key) => {
            o[key] = this._date(data.invoice.period[key]);
            return o;
          }, {});
        },
        async _remove() {
          this.remove.disabled = true;
          this._pending = true;
          try {
            var { _id: invoiceId } = data.invoice;
            var { methods } = data;
            var r = await Api.delete(`invoices/${invoiceId}`);
            if (methods.success)
              return methods.success(r.data);
            Modal.hide();
          }
          catch (e) {
            console.error(e);
            alert.error(e.message);
          }
          finally {
            this._pending = false;
            this.remove.disabled = false;
          }
        },
        _schedule(o) {
          var fees = [];
          var owing = 0;
          var total = 0;
          var splits = [];
          return { ...o, fees, owing, splits, total };
        },
        _schedules: ``,
        _show() {
          var { due, fees, numbers, tiers, negativeTotal } = data.invoice.show;
          return { all: { due, fees, tiers }, numbers, negativeTotal };
        },
        _text: { NONE: ``, SELF: ``, BTI: `` },
        _type() {
          return this.options(`type`).find((s) => s.toLowerCase() == data.invoice.type.toLowerCase());
        },
        _values() {
          return this.options(`period`, `values`)[0]
        },
        _update: () => new Promise((r) => this.$nextTick(r)),
        accounts(type) {
          var account = (o) => {
            if (!o.name) {
              o.name = this._accounts.find(a => a.number == o.number)?.name;
            }
            var len = Math.floor(o.number.length / 2);
            var number = this.show.numbers
              ? [...new Array(len).fill(`*`), o.number.slice(len)].join(``)
              : o.number;
            return { ...o, number, safeNumber: o.number };
          };
          switch (type) {
            case `due`:
              return this._accounts
                .filter((o) => o.due || this.show.all.due || this.hasLineItem(o))
                .map((o) => account(o));
            case `grandtotal`:
              return this._accounts
                .map((o) => account(o));
            case `fees`:
              var accounts = this._accounts.slice().map((o) => account(o));
              var { exceptions } = data.invoice;
              exceptions.forEach((e) => {
                var i = accounts.findIndex((a) => a.safeNumber == e.account);
                if (i < 0)
                  return;
                var number = this._text.NONE;
                var type = lib.string.capitalize(e.type);
                var ticker = e.ticker
                  ? this._data.contract.exceptions.find((o) => o.type == `position` && o.ticker.symbol == e.ticker)?.ticker
                  : ``;
                var exception = ticker
                  ? `Ticker: ${ticker.symbol} (${ticker.name})`
                  : type;
                var schedule = this._data.contract.schedules.find(s => s._id == e.scheduleId).name;
                var o = {
                  ...e,
                  name: exception,
                  number,
                  schedule,
                };
                accounts.splice(i + 1, 0, o);
              });
              const displayedAccounts = accounts.filter((o) => { 
                if (this.show.all.fees) return true  // show all accounts and exceptions if this is true
                if (o.billing && o.household) return true // keep the top level account showing even if fee = 0
                if (o.fee) return true  // finally check if the the fee > 0 then include it
                return false // everything else
               });
              return displayedAccounts
          }
        },
        get isAdvance() {
          return this.type.toLowerCase() == this.options(`type`)[0].toLowerCase();
        },
        get isLatest() {
          const { contract } = this._data
          return contract.billing.values.toLowerCase() == 'latest'
        },
        click(type) {
          switch (type) {
            case `export`:
              return this._export();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        date: ``,
        disabled(type) {
          switch (type) {
            case `export`:
              return this._pending;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init() {
          this.date = data.invoice.date;
          this.period = this._period();
          this.ref = data.invoice.ref;
          this.show = this._show();
          this.type = this._type();
          this._text = { NONE: ``, SELF: `Self`, BTI: `Bill to Invoice` };
          this._load();
          this.remove = {
            click: () => {
              this._remove();
            },
            disabled: ``,
          };
          return this;
        },
        options(type, d) {
          var format = this._date(`format`);
          var { date, period } = this;
          switch (type) {
            case `date`:
              return {
                format,
                dates: [date],
              };
            case `period`:
              switch (d) {
                case `start`:
                  return {
                    format,
                    dates: [period.start],
                  };
                case `end`:
                  return {
                    format,
                    dates: [period.end],
                  };
                case `values`:
                  var periodValues = data.invoice.period?.values
                  return (typeof period.values == `string`)
                    ? [period.values]
                    : this._data.contract?.billing.values == 'Latest'
                      ? [`Latest (${periodValues?.latest})`]
                      : [`Average Daily (${periodValues?.start} - ${periodValues?.end})`]
                default:
                  throw new Error(`Invalid ${type} d, ${d}!`);
              }
            case `type`:
              return [`advance`, `arrears`];
            default:
              throw new Error(`Invalid options type, ${type}!`);
          }
        },
        period: ``,
        ready: ``,
        ref: ``,
        remove: ``,
        get schedules() {
          return this._schedules;
        },
        getGroupRate(d, e) {
          function getRate(yearFee, split, period) {
            return (yearFee / (split * period)) * 100;
          }
          const a = moment(this.period.end);
          const b = moment(this.period.start);
          const days = a.diff(b, "days");
          const daysInYear = b.isLeapYear() ? 366 : 365;
          const split = d.splits[e];
          const fee = d.fees[e];
          const period = days / daysInYear;
          const hasRateApplied = this.groupSchedulesValues &&
            this.groupSchedulesValues[d.name] > d.breaks[1];
          const appliedRate = hasRateApplied
            ? getRate(fee, split, period)
            : d.rates[0];
          return `${numeral(appliedRate).format(`0,0.[00]`)}%`;
        },
        hasLineItem(account) {
          if (this.lineItems?.length) {
            for (const item of this.lineItems) {
              if (item.number == "*" || item.number == account.number) {
                return true;
              }
            }
          }
          return false;
        },
        getRebatedTransactions(account) {
          if (account.rebatedTransactions) {
            return account.rebatedTransactions;
          }
        },
        getAccountGrandTotal(account, forceZero=false) {
          if (account.grandTotal) {
            // if the grand total is < 0, we force it to be 0
            // as we'll use that negative amount later as a carryover line item
            return account.grandTotal < 0 && forceZero ? 0 : account.grandTotal;
          }
          const invoiceAcc = data?.invoice?.accounts?.find(a => a.number == account.safeNumber);
          if (invoiceAcc?.grandTotal !== undefined) {
            return invoiceAcc.grandTotal;
          }
          let v = account.due;
          const accountChanges = this.accountsChanges != ""
            ? this.accountsChanges?.find((acc) => acc.number == account.safeNumber)
            : 0;
          if (accountChanges)
            v += accountChanges.adjustment;

          if (this.lineItems?.length) {
            for (const item of this.lineItems) {
              if (item.number == "*" || item.number == account.safeNumber)
                v += calculateLineItemAmount(item, this.period)
            }
          }
          // if the grand total is < 0, we force it to be 0
          // as we'll use that negative amount later as a carryover line item
          return v < 0 && forceZero ? 0 : v ;
        },
        text(type, d, e) {
          var value = (v, f = `$0,0.00`) => numeral(v).format(f);
          var number = (n) => this.show.numbers
            ? [
              ...new Array(Math.floor(n.length / 2)).fill(`*`),
              n.slice(Math.floor(n.length / 2)),
            ].join(``)
            : n;
          switch (type) {
            case `account`:
              if (!d[type].includes(`(`))
                return d[type];
              var [firstPart, secondPart] = d[type].split(`)`);
              var n = number(firstPart.split(`(`).pop());
              return `(${n}) ${secondPart.trim()}`;
            case `amount`:
              return value(d[type]);
            case `amount-due`:
              const amountValue = calculateLineItemAmount(d, this.period)
              return value(amountValue)
            case `schedule-name`:
              const acs = this.adjustmentContract.schedules
              if (acs) return acs.find(s => s._id == d.scheduleId)?.name;
            case `account-name`:
              if (d.isNew) {
                const accountName = this._data.accounts.find(acc => acc.number == d.number)?.name;
                return accountName ? accountName + " (New Account)" : "(New Account)";
              }
              return this.adjustmentContract.accounts?.find((acc) => acc.number == d.number).name;
            case `account-grand-total`:
              var gTotal = this.getAccountGrandTotal(d)
              return value(gTotal < 0 ? 0 : gTotal);
            case `accounts`:
              switch (d) {
                case `due`:
                  return data.invoice.refund
                    ? `Accounts Refund`
                    : `Accounts Due`;
                default:
                  throw new Error(`Invalid ${type} ${d}!`);
              }
            case `schedule`:
              return `Schedule ${String.fromCharCode(97 + e).toUpperCase()} - ${d.name}`;
            case `tier`:
              var start = (v) => value(v == 0 ? v : v + 1, `$0,0`);
              var end = (v) => value(v, `$0,0`);
              return e < d.breaks.length - 1
                ? `${start(d.breaks[e])} - ${end(d.breaks[e + 1])}`
                : `> ${start(d.breaks[e])}`;
            case `rate`:
              return `${numeral(d.rates[e]).format(`0,0.[00]`)}%`;
            case `group-rate`:
              return this.getGroupRate(d, e);
            case `fee`:
            case `split`:
            case `due`:
            case `grandtotal`:
            case `value`:
              var v = typeof e == `number` ? d[`${type}s`][e] : d[type];
              return type == `fee`
                ? d.owing
                  ? `(*) ${value(v)}`
                  : value(v)
                : value(v);
            case `modal`:
              return `Invoice ${Record.id(data.invoice)} - ${data.invoice.ref}`;
            case `total`:
              var v, key;
              switch (d) {
                case `due`:
                  v = this.accounts(`due`).reduce((v, a) => (v += a[d]), 0);
                  break;
                case `grandtotal`:
                  v = this.accounts(`grandtotal`).reduce((v, a) => (v += a[d]), 0);
                  break;
                case `account-values`:
                case `account-fees`:
                  key = d.split(`-`).pop().slice(0, -1);
                  v = this.accounts(`fees`).reduce((v, a) => (v += a[key]), 0);
                  break;
                case `schedule-fees`:
                case `schedule-splits`:
                  key = d.split(`-`).pop();
                  v = e.total[key];
                  break;
                case `account-grand-total`:
                  v = this.accounts(`grandtotal`)
                  .reduce((v, a) => (v += this.getAccountGrandTotal(a, true)), 0);
                  break;
                case `accounts-changes-change`:
                case `accounts-changes-adjustment`:
                  key = d.split(`-`).pop();
                  v = this.accountsChanges.reduce((v, a) => (v += a[key]), 0);
                  break;
                case `schedules-changes-change`:
                case `schedules-changes-adjustment`:
                  key = d.split(`-`).pop();
                  v = this.schedulesChanges.reduce((v, a) => (v += a[key]), 0);
                  break;
                default:
                  throw new Error(`Invalid text ${type} d ${d}!`);
              }
              return value(v);
            case `owing`:
              return d
                ? d.minimum
                  ? `This schedule fees fell short of the pro-rata minimum (${value(d.minimum)}) by ${value(d.owing)}`
                  : ``
                : this.accounts(`fees`).find((o) => o.owing)
                  ? `(*) Fee includes a pro-rata amount based on the schedule minimum shortfall`
                  : ``;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        tiers(o) {
          return o.splits.filter((v) => v || this.show.all.tiers);
        },
        updateProratedDays(fundedDate) {
          const startDate = moment(this.isLatest ? this.period.start : this.period.values.start)
          const endDate = moment(this.isLatest ? this.period.end : this.period.values.end)
          const fundedD = moment(fundedDate)

          return fundedD.startOf('day').isBetween(startDate, endDate)  
            ? endDate.diff(fundedD, 'days') 
            : null
        }
      };
      this.invoice = invoice.init();
    },
  },
};
</script>
