<template>
  <div class="modal-dialog modal-dialog-centered modal-xl"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-file-alt mr-2"></i>
          <span class="font-weight-bold"
                v-text="nav.title(`modal`)"></span>
        </p>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <ul class="nav">
          <li v-for="(item, index) in nav.items"
              :key="index"
              class="nav-item mx-1"
              :class="nav.class(`nav`, item)">
            <a class="nav-link"
               v-on:click="nav.click(`nav`, item)">
              <span v-text="item"></span>
            </a>
          </li>
        </ul>
        <div class="component pl-2 pt-3">
          <component :is="nav.component"
                     :contract="contract"
                     :accounts="accounts"></component>
        </div>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <button type="button"
                  class="btn btn-sm btn-primary ml-2"
                  :disabled="nav.disabled(`submit`)"
                  v-on:click="nav.click(`submit`)">
            <span>Amend</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
div.ref input {
  width: 20em;
}

span.multi {
  font-size: 0.8em;
  opacity: 0.7;
  font-style: italic;
}

div.menu {
  height: 2.5em;
}

div.controls {
  width: 15em;
}

span.date {
  font-size: 0.7em;
}

button {
  width: 80px;
}

div.modal-body {
  height: calc(100vh - 12em);
}

div.component {
  height: calc(100% - 2.5em);
}

li {
  cursor: default;
}

li span {
  color: black;
  opacity: 0.7;
  font-weight: 600;
}

li.disabled span {
  opacity: 0.3;
}

li.active {
  border-bottom: 2px solid #0c2b4b;
}

li:not(.active):not(.disabled):hover {
  border-bottom: 2px solid lightgray;
  cursor: pointer;
}

li a {
  text-align: center;
}

p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
</style>

<script>
import { removeButton } from "../../../../../../component";
import { Api, Contract } from "../../../../../../factory";
import { alert } from "../../../../../../service";

import {
  accounts as accountsTab,
  exceptions as exceptionsTab,
  adjustments as adjustmentsTab,
  signing as signingTab,
  summary as summaryTab,
  // rebates as rebatesTab,
} from "./tab";

export default {
  get components() {
    return {
      accountsTab,
      exceptionsTab,
      adjustmentsTab,
      // rebatesTab,
      signingTab,
      summaryTab,
      removeButton,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (d) =>
        typeof d.contract == `object` &&
        typeof d.accounts == `object` &&
        typeof d.methods == `object` &&
        typeof d.methods.success == `function`,
    },
  },
  data() {
    return {
      nav: ``,
    };
  },
  computed: {
    contract() {
      return this.data.contract;
    },
    accounts() {
      return this.data.accounts;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { contract, methods, accounts } = this.data;
      contract.date = new Date()
      var nav = {
        _disabled(view) {
          switch (view) {
            case `Accounts`:
            case `Adjustments`:
            // case `Rebates`:
            case `Exceptions`:
              return false;
            case `Signing`:
              return !contract.hasOwnProperty(`exceptions`);
            case `Summary`:
              return !contract.hasOwnProperty(`signees`);
            default:
              throw new Error(`Invalid disabled view, ${view}!`);
          }
        },
        _init: (...args) => this.$set(...args),
        async _load() {
          try {
            const household = (
              await Api.get(`households/${contract.householdId}`)
            ).data;

            contract.accounts.forEach((a) => {
              a.household = household.name;
            });

            contract.data.households = [household];
            var { data: schedules } = await Api.get(`schedules`);
            contract.data.schedules = schedules;
            const itemMap = new Map(contract.accounts.map(a => [a.number, { ...a, select: true }]))

            for (let account of accounts) {
              if (itemMap.has(account.number)) {
                account = { ...itemMap.get(account.number), ...account }
                account.scheduleName = contract.schedules.find(s => s._id == account.scheduleId)?.name;
                account.billingNumber = account.billing == "-1" ? "Self" : account.billing;
                account.select = true;
                itemMap.set(account.number, account);
              } else {
                account.select = false;
                account.scheduleName = contract?.data?.schedules?.find(schedule => schedule.default)?.name ? contract.data.schedules.find(schedule => schedule.default).name : "";
                account.billingNumber = "Self";

                itemMap.set(account.number, {
                  ...account
                })
              }
            }

            contract.accounts = Array.from(itemMap.values())

            contract.exceptions.forEach((exception) => {
              const { type } = exception
              exception.typeSelected = type.split(' ').map(word => `${word[0].toUpperCase()}${word.slice(1)}`).join(' ')
              exception.accountSelected =
                exception.number == "*" ? "All Accounts" : exception.number;
              exception.scheduleName = contract.schedules.find(s => s._id == exception.scheduleId)?.name
            });


            this._ready = true;
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _pending: ``,
        _ready: ``,
        class(type, d) {
          switch (type) {
            case `nav`:
              return this._disabled(d)
                ? `disabled`
                : contract.state.item == d
                  ? `active`
                  : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        click(type, d) {
          switch (type) {
            case `nav`:
              if (!this._disabled(d)) contract.state.item = d;
              break;
            case `submit`:
              return this._submit();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        get component() {
          return this._ready ? `${contract.state.item.toLowerCase()}-tab` : ``;
        },
        disabled(type) {
          switch (type) {
            case `submit`:
              return contract.state.item !== 'Summary' || 
                     !contract.date || 
                     !contract.ref || 
                     this._pending;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        discard: ``,
        init() {
          if (!contract.hasOwnProperty(`state`)) {
            this._init(contract, `state`, {
              item: this.items[0],
            });
            this._init(contract, `data`, { households: [], schedules: [] });
          }
          this._pending = false;
          this._load();
          this.discard = {
            click: () => {
              methods.discard();
            },
            text: `Discard`,
          };
          return this;
        },
        item(item) {
          return contract.nav.item == item;
        },
        get items() {
          return [`Accounts`, `Adjustments`, `Signing`, `Summary`];
        },
        async _submit() {
          var {
            accounts,
            alerts,
            billing,
            date,
            ref,
            schedules,
            show,
            template,
            lineItems,
            storedLineItems,
            _id,
            transactionsRules,
          } = contract;
          
          var exceptions = Contract.exceptions(contract);
          var signees = Contract.signees(contract);

          const scheduleSet = new Set()

          accounts = accounts.filter(acc => acc.select).map(acc => {
            scheduleSet.add(acc.scheduleName)

            return {
              ...acc,
              schedule: acc.scheduleName,
              billing: acc.billingNumber
            }
          })

          exceptions = exceptions.map(exception => {
            scheduleSet.add(exception.scheduleName)
            return {
              ...exception,
              schedule: exception.scheduleName,
              account: exception.accountSelected,
              type: exception.typeSelected.toLowerCase()
            }
          })

          schedules = contract.data.schedules.filter(s => scheduleSet.has(s.name))

          var c = {
            accounts,
            last_id: _id,
            alerts,
            billing,
            date,
            exceptions,
            ref,
            schedules,
            show,
            signees,
            templateId: template?.id,
            storedLineItems,
            lineItems: lineItems.map((lineItem) => {
              const regexpAccountNumber = /\((.*?)\)/;
              const match = regexpAccountNumber.exec(lineItem.account) || [
                "*",
                "*",
              ];

              return {
                ...lineItem,
                number: lineItem.account == "All Accounts" ? "*" : match[1],
                amount: parseFloat(lineItem.amount),
              };
            }),
            transactionsRules
          };

          try {
            this._pending = true;
            var r = await Api.post(`contracts/amend`, c);
            alert.message(r.data, 3e3);
            methods.success(r.data);
          } catch (e) {
            console.error(e);
            if (methods && typeof methods.error == `function`)
              return methods.error(e);
            alert.error(e.message);
            this._pending = false;
          }
        },
        title(type) {
          switch (type) {
            case `modal`:
              var households = Array.isArray(contract.data.households)
                ? contract.data.households.map((o) => o.name)
                : [];
              return households.length
                ? `Amend Contract - ${households.join(`, `)}`
                : `Amend Contract`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
      };
      this.nav = nav.init();
    },
  },
};
</script>
