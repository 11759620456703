
<div class="h-100">
  <div class="search">
    <search :o="table.list"></search>
  </div>
  <div class="d-flex p-1 pb-2 header">
    <div class="d-flex actions mr-2">
      <div title="Create Contract" 
      class="btn btn-outline-secondary action-button"
      v-on:click="table.click(`create`, `contract`)">
        <i class="mr-1 fas fa-file-signature"></i>
        <span>Create Contract</span>
      </div>
    </div>
    <div class="flex-grow-1 text-right">
      <span v-text="table.records"></span>
    </div>
  </div>
  <template v-if="ready">
  <scroll-area class="pr-3">
    <table class="table table-sm">
      <thead>
        <tr>
          <th>
            <span>Number</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `number`)"
              v-on:click="table.sort(`number`)"
            ></i>
          </th>
          <th>
            <span>Name</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `name`)"
              v-on:click="table.sort(`name`)"
            ></i>
          </th>
          <th class="text-right">
            <span>Value</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `value`)"
              v-on:click="table.sort(`value`)"
            ></i>
          </th>
          <th>
            <span class="pl-3">Contract</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `contract`)"
              v-on:click="table.sort(`contract`)"
            ></i>
          </th>
          <th>
            <span>Updated</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `updated`)"
              v-on:click="table.sort(`updated`)"
            ></i>
          </th>
          <th>
            <checkbox
              :o="table"
              p="select"
              :title="table.title(`select`)"
              :disabled="table.disabled(`select`)"
              v-on:change="table.change(`select`)"
            ></checkbox>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, index) in table.items" :key="index">
          <td class="pre">
            <a href="#"
              class="badge badge-custom"
              :title="table.title(`account`)"
              v-on:click="table.click(`account`, o)"
              v-text="o.number"
            ></a>
          </td>
          <td class="pre">
            <span v-text="o.name"></span>
          </td>
          <td class="text-right">
            <template v-if="o.value != table.NONE">
              <a href="#"
                class="badge badge-custom"
                :title="table.title(`records`)"
                v-on:click="table.click(`value`, o)"
                v-text="table.display(`value`, o)"
              ></a>
            </template>
            <template v-else>
              <span v-text="o.value"></span>
            </template>
          </td>
          <td class="text-center">
            <template v-if="o.contract != table.NONE">
              <a href="#"
                class="badge badge-custom"
                :title="table.title(`contract`)"
                v-on:click="table.click(`contract`, o)"
                v-text="o.contract"
              ></a>
            </template>
            <template v-else>
              <span class="pl-3" v-text="o.contract"></span>
            </template>
          </td>
          <td>
            <span v-text="o.updated"></span>
          </td>
          <td>
            <checkbox :o="o" p="select" :disabled="table.disabled(`select`, o)"></checkbox>
          </td>
        </tr>
      </tbody>
    </table>
  </scroll-area>
  </template>
  <template v-else>
      <loading></loading>
    </template>
</div>
