
<div class="h-100">
  <div class="search">
    <search :o="table.list"></search>
  </div>
  <scroll-area class="pr-3">
    <table class="table table-sm">
      <caption v-if="table._firmSignRequestsLimit != undefined">
          <em>Remaining Signature Requests: {{ table._firmSignRequestsLimit 
          ? table._firmSignRequestsLimit : 'None. (need more? contact sales@smartkx.io)' }}
         </em> 
      </caption>        
      <thead>
        <tr>
          <th>
            <checkbox :disabled="!table._firmSignRequestsLimit" class="align-middle"
                      :o="table"
                      p="select"
                      v-on:change="table.change(`select`)"></checkbox>
          </th>
          <th>
            <span>Name</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `name`)"
               v-on:click="table.sort(`name`)"></i>
          </th>
          <th>
            <span>Email</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `email`)"
               v-on:click="table.sort(`email`)"></i>
          </th>
          <th>
            <span>Role</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `role`)"
               v-on:click="table.sort(`role`)"></i>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, index) in table.items"
            :key="index">
          <td>
            <checkbox :disabled="!table._firmSignRequestsLimit" class="align-middle"
                      :o="o"
                      p="select"
                      title="Select signee"></checkbox>
          </td>
          <td>
            <span v-text="o.name"></span>
          </td>
          <td>
            <span v-text="o.email"></span>
          </td>
          <td>
            <span class="text-capitalize"
                  v-text="o.role"></span>
          </td>
          <td class="text-right">
            <i class="fas fa-long-arrow-alt-up mr-1"
               :class="table.class(`up`, index)"
               :title="table.title(`up`, index)"
               v-on:click="table.click(`up`, index)"></i>
            <i class="fas fa-long-arrow-alt-down"
               :class="table.class(`down`, index)"
               :title="table.title(`down`, index)"
               v-on:click="table.click(`down`, index)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </scroll-area>
</div>
